import { amber } from '@material-ui/core/colors';

export default (theme) => ({
	root: (props) => ({
		position: props.position,
		width: `${40 * props.scale}px`,
		height: `${40 * props.scale}px`,
		fontSize: `${1.25 * props.scale}rem`,
		backgroundColor: 'transparent'
	}),
	toolbarRoot: (props) => ({
		position: props.position,
		width: `${40 * props.scale}px`,
		height: `${40 * props.scale}px`,
		fontSize: `${1.25 * props.scale}rem`,
		color: 'inherit',
		backgroundColor: 'transparent'
	}),
	listRoot: (props) => ({
		position: props.position,
		width: `${40 * props.scale}px`,
		height: `${40 * props.scale}px`,
		fontSize: `${1.25 * props.scale}rem`,
		backgroundColor: amber[600]
	}),
	cardRoot: (props) => ({
		position: props.position,
		width: `${40 * props.scale}px`,
		height: `${40 * props.scale}px`,
		fontSize: `${1.25 * props.scale}rem`,
		backgroundColor: amber[600]
	}),
	nameInitials: {
		cursor: 'default'
	}
});
