import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMessaging, useUser, useFirestore } from 'reactfire';
import { useNotifications } from 'modules/notification';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { firebaseMessagingConfig } from 'config';
import { NOTIFICATIONS_COLLECTION } from 'constants/firebasePaths';

export const MessagingContext = React.createContext({
	isSupported: false,
	requestPermission: () => {},
	isPermissionGranted: false,
	token: undefined
});

const MessagingProviderInternal = ({ children }) => {
	const firestore = useFirestore();
	const { FieldValue } = useFirestore;
	const messaging = useMessaging();
	const { data: user } = useUser();
	const { showInfo, close } = useNotifications();
	const { t } = useTranslation();
	const history = useHistory();

	let messagingInitialized = false;
	const [token, setToken] = useState(undefined);
	const [isPermissionGranted, setIsPermissionGranted] = useState(false);

	const isSupported = useMessaging.isSupported();

	useEffect(() => {
		// eslint-disable-next-line no-undef
		if (Notification.permission === 'granted') {
			initializeMessaging().then(() => setIsPermissionGranted(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const syncToken = async (token) => {
		setToken(token);
		try {
			if (user.uid) {
				return firestore.doc(`${NOTIFICATIONS_COLLECTION}/${user.uid}`).set(
					{
						token: token,
						updatedAt: FieldValue.serverTimestamp()
					},
					{ merge: true }
				);
			}
		} catch (error) {
			console.warn(error);
			return Promise.reject(error);
		}
	};

	const handleMessageLink = (key, data) => {
		close(key);
		if (data.type === 'new_rating' || data.type === 'new_post') {
			removeNotificationItem(data.id);
			history.push(`post?id=${data.postId}`);
		}
	};

	const removeNotificationItem = async (id) => {
		try {
			const doc = await firestore.doc(`${NOTIFICATIONS_COLLECTION}/${user.uid}`).get();
			if (doc.exists) {
				const data = doc.data();
				const newItems = data.items.filter(function (item) {
					return item.id !== id;
				});
				await firestore
					.doc(`${NOTIFICATIONS_COLLECTION}/${user.uid}`)
					.set({ items: newItems }, { merge: true });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleMessageAction = (key, data) => {
		return (
			<Fragment>
				{data ? (
					<Button onClick={() => handleMessageLink(key, data)} color="inherit">
						{t('messaging_show')}
					</Button>
				) : null}
				<IconButton onClick={() => close(key)}>
					<CloseIcon />
				</IconButton>
			</Fragment>
		);
	};

	const initializeMessaging = async () => {
		if (messagingInitialized) {
			return;
		}

		messaging.onMessage((payload) => {
			showInfo(`${payload.data.body}`, {
				variant: 'default',
				persist: false,
				// style: { whiteSpace: 'pre-line' },
				action: (key) => handleMessageAction(key, payload.data)
			});
		});

		// messaging.onTokenRefresh(() => {
		// 	messaging
		// 		.getToken()
		// 		.then((t) => syncToken(t))
		// 		.catch((e) => {
		// 			console.log('Unable to retrieve refreshed token ', e);
		// 		});
		// });
		messagingInitialized = true;

		try {
			const token = await messaging.getToken({ vapidKey: firebaseMessagingConfig.publicVapidKey });
			await syncToken(token);
		} catch (error) {
			console.log('Unable to retrieve token ', error);
		}
	};

	const requestPermission = async () => {
		if (!('Notification' in window)) {
			console.log('This browser does not support desktop notification');
		}

		// eslint-disable-next-line no-undef
		if (Notification.permission === 'default') {
			// eslint-disable-next-line no-undef
			const permission = await Notification.requestPermission();
			if (permission === 'granted') {
				await initializeMessaging();
				setIsPermissionGranted(true);
			}
			// eslint-disable-next-line no-undef
		} else if (Notification.permission === 'granted') {
			await initializeMessaging();
			setIsPermissionGranted(true);
		}
	};

	const contextValue = {
		isSupported: isSupported,
		requestPermission: isSupported ? requestPermission : () => {},
		// eslint-disable-next-line no-undef
		isPermissionGranted: isSupported && isPermissionGranted,
		token: isSupported ? token : undefined
	};

	return <MessagingContext.Provider value={contextValue}>{children}</MessagingContext.Provider>;
};

MessagingProviderInternal.propTypes = {
	children: PropTypes.any
};

export default function MessagingProvider({ children }) {
	const user = useUser();
	const { isSupported } = useMessaging;

	// Render nothing if user is not logged in or if messaging is not supported
	if (!user?.data?.uid || !isSupported()) {
		if (!isSupported()) {
			console.log('Messaging not supported');
		}
		return <>{children}</>;
	}

	// Load messaging if user is logged in
	return <MessagingProviderInternal>{children}</MessagingProviderInternal>;
}

MessagingProvider.propTypes = {
	children: PropTypes.any
};
