import React, { useState, useEffect } from 'react';
import { useFirestore, useFirestoreDocData, useUser } from 'reactfire';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
	Badge,
	Box,
	Divider,
	IconButton,
	Grid,
	ListItemIcon,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
	Typography
} from '@material-ui/core';
import {
	ChatRounded as ChatIcon,
	ClearAll as ClearAllIcon,
	Close as CloseIcon,
	Notifications as NotificationsIcon,
	StarRounded as StarIcon
} from '@material-ui/icons';

import styles from './NavigationBar.styles';
import { NOTIFICATIONS_COLLECTION } from 'constants/firebasePaths';

const ITEM_HEIGHT = 60;
const useStyles = makeStyles(styles);

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: -3,
		border: `2px solid ${theme.palette.primary.main}`,
		padding: '0 4px'
	}
}))(Badge);

const NotificationMenu = () => {
	const classes = useStyles();
	const firestore = useFirestore();
	const { data: user } = useUser();
	const { t } = useTranslation();
	const history = useHistory();

	const userNotificationsRef = firestore.collection(NOTIFICATIONS_COLLECTION).doc(user.uid);
	const userNotificationsResult = useFirestoreDocData(userNotificationsRef, {
		initialData: {
			items: []
		}
	});
	const { data: userNotifications } = userNotificationsResult;

	const [anchorEl, setAnchorEl] = useState(null);
	const [items, setItems] = useState([]);
	const [count, setCount] = useState(0);

	const menuId = 'main-notification-menu';

	useEffect(() => {
		setCount(userNotifications?.items?.length ?? 0);
		setItems(userNotifications?.items ?? []);
	}, [userNotifications]);

	const handleMenu = (event) => setAnchorEl(event.currentTarget);

	const closeMenu = () => setAnchorEl(null);

	const onNotificationClick = (event, item) => {
		event.preventDefault();

		if (item.type === 'new_rating' || item.type === 'new_post') {
			closeMenu();
			removeNotificationItems([item.id]);
			history.push(`post?id=${item.postId}`);
		}
	};

	const onRemoveNotificationClick = (event, itemId) => {
		event.preventDefault();
		if (count === 1) {
			closeMenu();
		}
		removeNotificationItems([itemId]);
	};

	const onRemoveAllNotificationsClick = (event) => {
		event.preventDefault();
		closeMenu();
		removeNotificationItems(items.map((i) => i.id));
	};

	const removeNotificationItems = async (toRemove) => {
		try {
			const newItems = items.filter(function (item) {
				return !toRemove.includes(item.id);
			});
			await firestore.doc(`${NOTIFICATIONS_COLLECTION}/${user.uid}`).set({ items: newItems }, { merge: true });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<IconButton
				aria-owns={anchorEl ? menuId : null}
				aria-controls={anchorEl ? menuId : null}
				aria-haspopup="true"
				disabled={count === 0}
				onClick={handleMenu}
				classes={{ root: classes.buttonRoot }}>
				<StyledBadge badgeContent={count} color="secondary" max={99} overlap="circle">
					<NotificationsIcon />
				</StyledBadge>
			</IconButton>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 5,
						width: '360px'
					}
				}}>
				<Box mx={2} mt={1} mb={1.5}>
					<Grid container spacing={2} alignItems="center" justify="space-between">
						<Grid item>
							<Typography variant="subtitle2">{t('notifications_title', { count: count })}</Typography>
						</Grid>
						<Grid item>
							<IconButton edge="end" size="small" onClick={onRemoveAllNotificationsClick}>
								<ClearAllIcon fontSize="small" />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
				<Box my={1}>
					<Divider />
				</Box>
				{items.map((item) => (
					<MenuItem
						key={item.id}
						className={classes.notificationItem}
						onClick={(event) => onNotificationClick(event, item)}>
						<ListItemIcon className={classes.notificationItemIcon}>
							{item.type === 'new_rating' ? (
								<StarIcon color="secondary" />
							) : item.type === 'new_post' ? (
								<ChatIcon color="primary" />
							) : null}
						</ListItemIcon>
						<Typography variant="body2" className={classes.notificationItemText}>
							{item.text}
						</Typography>
						<ListItemSecondaryAction>
							<IconButton
								edge="end"
								size="small"
								onClick={(event) => onRemoveNotificationClick(event, item.id)}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</ListItemSecondaryAction>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default NotificationMenu;
