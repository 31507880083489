import { pink } from '@material-ui/core/colors';

export default {
	palette: {
		primary: {
			main: '#334160'
		},
		secondary: {
			main: pink.A400
		}
	},
	typography: {
		fontFamily: ['Mukta', 'Roboto', '"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'].join(','),
		fontSize: 15
	},
	flexColumnCenter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	flexRowCenter: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column'
	}
};
