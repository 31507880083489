import React from 'react';
import PropTypes from 'prop-types';
import {
	FirebaseAppProvider,
	preloadFirestore,
	preloadDatabase,
	preloadStorage,
	preloadAuth,
	useFirebaseApp
} from 'reactfire';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationsProvider } from 'modules/notification';
import { ThemeProvider } from 'modules/theme';
import { ServiceWorkerProvider } from 'modules/serviceWorker';
import { MessagingProvider } from 'modules/messaging';
import SetupFirestore from 'components/SetupFirestore';
import SetupPresence from 'components/SetupPresence';
import SetupUpdater from 'components/SetupUpdater';
import NavigationBar from 'components/NavigationBar';
import ErrorFallback from 'components/ErrorFallback';
import createRoutes from './routes';

import { firebaseConfig } from './config';

// Enable Real Time Database emulator if environment variable is set
if (process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST) {
	firebaseConfig.databaseURL = `http://${process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST}?ns=${firebaseConfig.projectId}`;
	console.debug(`RTDB emulator enabled: ${firebaseConfig.databaseURL}`); // eslint-disable-line no-console
}

const preloadSDKs = (firebaseApp) => {
	return Promise.all([
		preloadFirestore({
			firebaseApp,
			setup(firestore) {
				return firestore().enablePersistence({ synchronizeTabs: true });
			}
		}),
		preloadDatabase({ firebaseApp }),
		preloadStorage({
			firebaseApp,
			setup(storage) {
				return storage().setMaxUploadRetryTime(10000);
			}
		}),
		preloadAuth({ firebaseApp })
		//   preloadRemoteConfig({
		// 	firebaseApp,
		// 	setup(remoteConfig) {
		// 	  remoteConfig().settings = {
		// 		minimumFetchIntervalMillis: 10000,
		// 		fetchTimeoutMillis: 10000
		// 	  };
		// 	  return remoteConfig().fetchAndActivate();
		// 	}
		//   })
	]);
};

const RootErrorFallback = ({ error, resetErrorBoundary }) => {
	return (
		<div role="alert">
			<p>Ooops! Da ist etwas schief gegangen:</p>
			<table>
				<tbody>
					<tr>
						<td>
							<b>Fehlermeldung:</b>
						</td>
						<td>{error.message}</td>
					</tr>
					<tr>
						<td valign="top">
							<b>Stacktrace:</b>
						</td>
						<td>
							<pre style={{ margin: 0 }}>{error.stack}</pre>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

RootErrorFallback.propTypes = {
	error: PropTypes.object,
	resetErrorBoundary: PropTypes.func
};

const rootErrorHandler = (error, info) => {
	console.error(error);
	console.log(`Schwasenphrein error occurred at:\r\n${info.componentStack}`);
};

export const AppWrapper = () => {
	return (
		<ErrorBoundary FallbackComponent={RootErrorFallback} onError={rootErrorHandler}>
			<FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
				<ThemeProvider>
					<ErrorBoundary FallbackComponent={ErrorFallback} onError={rootErrorHandler}>
						<NotificationsProvider>
							<ServiceWorkerProvider>
								<App />
							</ServiceWorkerProvider>
						</NotificationsProvider>
					</ErrorBoundary>
				</ThemeProvider>
			</FirebaseAppProvider>
		</ErrorBoundary>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		flexGrow: 1
	},
	offset: theme.mixins.toolbar
}));

const App = () => {
	const classes = useStyles();
	const firebaseApp = useFirebaseApp();

	const routes = createRoutes();

	preloadSDKs(firebaseApp);

	return (
		<>
			<Router>
				<MessagingProvider>
					{/* <div className={classes.container}> */}
					<NavigationBar />
					<div className={classes.offset} />
					{routes}
					{/* </div> */}
				</MessagingProvider>
			</Router>
			<SetupFirestore />
			<SetupPresence />
			<SetupUpdater />
		</>
	);
};
