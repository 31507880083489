import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ThemeSettings from '../../theme';
import { useLocalStorage } from 'utils/useLocalStorage';

export const ThemeContext = React.createContext({
	isDarkMode: false,
	toggleDarkMode: () => {}
});

export default function ThemeProvider({ children }) {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
	const [isDarkMode, setIsDarkMode] = useState(false);
	const [themeMode, setThemeMode] = useLocalStorage('themeMode', prefersDarkMode ? 'dark' : 'light');

	const changeIsDarkMode = useCallback(
		(value) => {
			const mode = value ? 'dark' : 'light';
			setThemeMode(mode);
		},
		[setThemeMode]
	);

	useEffect(() => setIsDarkMode(themeMode === 'dark'), [themeMode]);

	const theme = React.useMemo(
		() =>
			createMuiTheme({
				...ThemeSettings,
				palette: {
					...ThemeSettings.palette,
					type: isDarkMode ? 'dark' : 'light',
					primary: {
						main: isDarkMode ? '#5f6c8d' : '#334160'
					},
					secondary: {
						main: isDarkMode ? pink.A100 : pink.A400
					}
				}
			}),
		[isDarkMode]
	);

	const contextValue = {
		isDarkMode,
		toggleDarkMode: useCallback(() => changeIsDarkMode(!isDarkMode), [changeIsDarkMode, isDarkMode])
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			<MuiThemeProvider theme={theme}>
				{children}
				<CssBaseline />
			</MuiThemeProvider>
		</ThemeContext.Provider>
	);
}

ThemeProvider.propTypes = {
	children: PropTypes.element
};
