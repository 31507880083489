import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const NavigationItem = ({ icon, primary, secondary, to }) => (
	<ListItem
		button
		component={React.forwardRef(function navigationLink(props, ref) {
			return <Link to={to} {...props} ref={ref} />;
		})}>
		{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
		<ListItemText primary={primary} secondary={secondary} />
	</ListItem>
);

NavigationItem.propTypes = {
	icon: PropTypes.node,
	primary: PropTypes.string.isRequired,
	secondary: PropTypes.string,
	to: PropTypes.string.isRequired
};

export default NavigationItem;
