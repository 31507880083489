import React from 'react';
import { useUser, AuthCheck } from 'reactfire';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styles from './HomePage.styles';
import Pig from '../Pig';
import SignIn from '../SignIn';

const useStyles = makeStyles(styles);

function UserWelcome() {
	const { data: user } = useUser();
	const { t } = useTranslation();

	if (!user) {
		return null;
	}

	return (
		<Typography
			variant="h5"
			style={{
				fontFamily: 'Nanum Pen Script',
				fontSize: '3rem'
			}}>
			{t('landing_hello', { name: user.displayName })}
		</Typography>
	);
}

const Home = () => {
	const classes = useStyles();

	return (
		<main className={classes.main}>
			<Grid container alignItems="center">
				<Grid item xs={12} container>
					<Grid item xs={12} container justify="center" className={classes.containerPig}>
						<Grid item xs={6} sm={3}>
							<Pig width="100%" height="100%" />
						</Grid>
					</Grid>
					<Grid item xs={12} container justify="center">
						<Grid item>
							<AuthCheck fallback={<SignIn />}>
								<UserWelcome />
							</AuthCheck>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</main>
	);
};

export default Home;
