const styles = (theme) => ({
	containerEmail: {
		marginBottom: theme.spacing(2)
	},
	containerPassword: {
		marginBottom: theme.spacing(4)
	},
	containerError: {
		marginBottom: theme.spacing(1)
	},
	dialogActionsRoot: {
		justifyContent: 'normal'
	},
	buttonCancel: {
		marginLeft: 'auto',
		marginRight: theme.spacing(1)
	}
});

export default styles;
