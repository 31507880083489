import { useEffect } from 'react';
import { useDatabase, useUser } from 'reactfire';
import { PRESENCE_COLLECTION } from 'constants/firebasePaths';

const SetupPresence = () => {
	const { data: user } = useUser();
	const database = useDatabase();
	const { ServerValue } = useDatabase;

	useEffect(() => {
		if (!user) {
			return null;
		}

		const connectedRef = database.ref('.info/connected');
		const userRef = database.ref(`${PRESENCE_COLLECTION}/${user.uid}`);

		connectedRef.on('value', function (snapshot) {
			if (snapshot.val()) {
				const onlineRef = userRef.child('online');
				onlineRef.onDisconnect().set(false);
				onlineRef.set(true);

				const lastSeenRef = userRef.child('lastSeen');
				lastSeenRef.onDisconnect().set(ServerValue.TIMESTAMP);
				// const sessionRef = userRef.push();
				// sessionRef.child('ended').onDisconnect().set(ServerValue.TIMESTAMP);
				// sessionRef.child('began').set(ServerValue.TIMESTAMP);
			}
		});

		return () => {
			connectedRef.off('value');
		};
	}, [database, user, ServerValue]);

	return null;
};

export default SetupPresence;
