import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import { PrivateRoute, AdminRoute } from '../utils/router';
import AboutRoute from './About';
import AccountRoute from './Account';
import Admin from './Admin';
import HomeRoute from './Home';
import ImprintRoute from './Imprint';
import NotFoundRoute from './NotFound';
import PasswordForgetRoute from './PasswordForget';
import PostsRoute from './Posts';
import PrivacyPolicyRoute from './PrivacyPolicy';
import SettingsRoute from './Settings';
import SinglePostRoute from './SinglePost';

export default function createRoutes() {
	return (
		<Suspense fallback={<LoadingSpinner />}>
			<Switch>
				{/* eslint-disable-next-line react/jsx-pascal-case */}
				<Route exact path={HomeRoute.path} component={HomeRoute.component} />
				{
					/* Build Route components from routeSettings */
					[
						AboutRoute,
						AccountRoute,
						Admin,
						ImprintRoute,
						PasswordForgetRoute,
						PostsRoute,
						PrivacyPolicyRoute,
						SettingsRoute,
						SinglePostRoute
					].map((settings) =>
						settings.adminRequired ? (
							<AdminRoute key={`Route-${settings.path}`} {...settings} />
						) : settings.authRequired ? (
							<PrivateRoute key={`Route-${settings.path}`} {...settings} />
						) : (
							<Route key={`Route-${settings.path}`} {...settings} />
						)
					)
				}
				<Route component={NotFoundRoute.component} />
			</Switch>
		</Suspense>
	);
}
