import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const configuration = {
	debug: false,
	fallbackLng: 'de',
	// preload: ['de', 'en', 'es', 'tlh'],
	load: 'languageOnly',
	interpolation: {
		escapeValue: false // not needed for react as it escapes by default
	},
	react: {
		useSuspense: true
	},
	backend: {
		backends: [LocalStorageBackend, HttpBackend],
		backendOptions: [
			{
				defaultVersion: '3'
			},
			{
				loadPath: '/locales/{{lng}}/{{ns}}.json',
				addPath: '/locales/add/{{lng}}/{{ns}}'
			}
		]
	}
};

if (!i18n.isInitialized) {
	i18n
		// load translation using http -> see /public/locales
		// learn more: https://github.com/i18next/i18next-http-backend
		.use(Backend)
		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetector
		.use(LanguageDetector)
		// pass the i18n instance to react-i18next.
		.use(initReactI18next)
		// init i18next
		// for all options read: https://www.i18next.com/overview/configuration-options
		.init(configuration, (err) => {
			if (err) console.error(err);
		});
}

export default i18n;
