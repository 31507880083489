import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from './UserPicture.styles';

import { Avatar, ListItemAvatar, Badge } from '@material-ui/core';
import { Person as PersonIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons';

const badgeStyles = (theme) => ({
	badge: {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: '$ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""'
		}
	},
	colorPrimary: {
		color: 'inherit',
		boxShadow: `0 0 0 2px ${theme.palette.primary.main}`
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0
		}
	}
});
const StyledBadge = withStyles(badgeStyles)(Badge);

const useStyles = makeStyles(styles);

const UserPicture = ({ context, url, name, position, scale, isOnline, useName }) => {
	const classes = useStyles({ position: position, scale: scale, hasUrl: !!url });
	const firstChar = name.charAt(0);

	switch (context) {
		case 'standalone': {
			return (
				<StyledBadge
					overlap="circle"
					invisible={!isOnline}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					variant="dot">
					<Avatar alt={name} className={classes.root} src={url}>
						{useName ? (
							<span className={classes.nameInitials}>{firstChar}</span>
						) : (
							<PersonIcon style={{ fontSize: 'inherit' }} />
						)}
					</Avatar>
				</StyledBadge>
			);
		}
		case 'toolbar': {
			return (
				<StyledBadge
					overlap="circle"
					color="primary"
					invisible={!isOnline}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					variant="dot">
					<Avatar alt={name} className={classes.toolbarRoot} src={url}>
						{useName ? <span className={classes.nameInitials}>{firstChar}</span> : <AccountCircleIcon />}
					</Avatar>
				</StyledBadge>
			);
		}
		case 'card': {
			return (
				<StyledBadge
					overlap="circle"
					invisible={!isOnline}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					variant="dot">
					<Avatar alt={name} src={url} className={classes.cardRoot}>
						{useName ? (
							<span className={classes.nameInitials}>{firstChar}</span>
						) : (
							<PersonIcon style={{ fontSize: 'inherit' }} />
						)}
					</Avatar>
				</StyledBadge>
			);
		}
		case 'list': {
			return (
				<ListItemAvatar>
					<StyledBadge
						overlap="circle"
						invisible={!isOnline}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right'
						}}
						variant="dot">
						<Avatar alt={name} src={url} className={classes.listRoot}>
							{useName ? (
								<span className={classes.nameInitials}>{firstChar}</span>
							) : (
								<PersonIcon style={{ fontSize: 'inherit' }} />
							)}
						</Avatar>
					</StyledBadge>
				</ListItemAvatar>
			);
		}
		default:
			return null;
	}
};

UserPicture.defaultProps = {
	url: null,
	context: 'standalone',
	position: 'relative',
	scale: 1,
	isOnline: false,
	useName: false
};

UserPicture.propTypes = {
	url: PropTypes.string,
	name: PropTypes.string.isRequired,
	context: PropTypes.string,
	position: PropTypes.string,
	scale: PropTypes.number,
	isOnline: PropTypes.bool,
	useName: PropTypes.bool
};

export default UserPicture;
