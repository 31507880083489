import React from 'react';
import ReactDOM from 'react-dom';
import { version } from '../package.json';
import { AppWrapper as App } from './App';
import './index.css';
import './i18n';

import './apocalypseHandler';
import LoadingSpinner from 'components/LoadingSpinner';

// Window Variables
// ------------------------------------
window.version = version;

ReactDOM.render(
	<React.Suspense fallback={<LoadingSpinner />}>
		<App />
	</React.Suspense>,
	document.getElementById('root')
);
