import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';

export const NotificationsContext = React.createContext({
	showSuccess: () => {},
	showError: () => {},
	showWarning: () => {},
	showInfo: () => {},
	close: (key) => {}
});

export default function NotificationsProvider({ children }) {
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const contextValue = {
		showSuccess: useCallback(
			(message, settings) =>
				notistackRef.current.enqueueSnackbar(message, {
					...settings,
					variant: 'success',
					autoHideDuration: 4000
				}),
			[notistackRef]
		),
		showError: useCallback(
			(message, settings) =>
				notistackRef.current.enqueueSnackbar(message, {
					...settings,
					variant: 'error',
					autoHideDuration: 5000
				}),
			[notistackRef]
		),
		showWarning: useCallback(
			(message, settings) =>
				notistackRef.current.enqueueSnackbar(message, {
					...settings,
					variant: 'warning',
					autoHideDuration: 5000
				}),
			[notistackRef]
		),
		showInfo: useCallback(
			(message, settings) => notistackRef.current.enqueueSnackbar(message, { ...settings, variant: 'info' }),
			[notistackRef]
		),
		close: useCallback((key) => notistackRef.current.closeSnackbar(key), [notistackRef])
	};

	return (
		<NotificationsContext.Provider value={contextValue}>
			<SnackbarProvider
				ref={notistackRef}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				autoHideDuration={2500}
				TransitionComponent={Slide}
				action={(key) => (
					<IconButton key={`close-${key}`} aria-label="Close" color="inherit" onClick={onClickDismiss(key)}>
						<CloseIcon />
					</IconButton>
				)}>
				{children}
			</SnackbarProvider>
		</NotificationsContext.Provider>
	);
}

NotificationsProvider.propTypes = {
	children: PropTypes.element
};
