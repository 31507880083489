import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

export const ServiceWorkerContext = React.createContext({
	isUpdateAvailable: false,
	updateAssets: () => {}
});

export default function ServiceWorkerProvider({ children }) {
	const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
	const [updateAvailable, setUpdateAvailable] = useState(false);

	useEffect(() => {
		serviceWorkerRegistration.register({
			onUpdate: (registration) => {
				console.log('Service Worker Provider: onUpdate received');
				setWaitingServiceWorker(registration.waiting);
				setUpdateAvailable(true);
			},
			onWaiting: (registration) => {
				console.log('Service Worker Provider: onWaiting received');
				setWaitingServiceWorker(registration.waiting);
				setUpdateAvailable(true);
			}
		});
	}, []);

	// useEffect(() => {
	// 	if (updateAvailable) {
	// 		enqueueSnackbar(t("app_new_version_available"), {
	// 			persist: true,
	// 			variant: "success",
	// 			action: serviceWorkerRefreshAction(),
	// 		});
	// 	}
	// });

	useEffect(() => {
		// We setup an event listener to automatically reload the page
		// after the Service Worker has been updated, this will trigger
		// on all the open tabs of our application, so that we don't leave
		// any tab in an incosistent state
		function onStateChanged(event) {
			if (event.target.state === 'activated') {
				window.location.reload();
			}
		}

		if (!waitingServiceWorker) {
			return;
		}

		waitingServiceWorker.addEventListener('statechange', onStateChanged);
		return () => waitingServiceWorker.removeEventListener('statechange', onStateChanged);
	}, [waitingServiceWorker]);

	const contextValue = {
		isUpdateAvailable: updateAvailable,
		updateAssets: useCallback(() => {
			if (waitingServiceWorker) {
				console.log('Service Worker Provider: sending skip message to waiting worker');
				// We send the SKIP_WAITING message to tell the Service Worker
				// to update its cache and flush the old one
				waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
				setUpdateAvailable(false);
			}
		}, [waitingServiceWorker])
	};

	return <ServiceWorkerContext.Provider value={contextValue}>{children}</ServiceWorkerContext.Provider>;
}

ServiceWorkerProvider.propTypes = {
	children: PropTypes.element
};
