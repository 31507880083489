import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'modules/notification';
import { useServiceWorker } from 'modules/serviceWorker';
import { Button } from '@material-ui/core';

const SetupUpdater = () => {
	const { isUpdateAvailable, updateAssets } = useServiceWorker();
	const { showSuccess } = useNotifications();
	const { t } = useTranslation();

	const serviceWorkerRefreshAction = (key) => {
		return (
			<>
				<Button className="snackbar-button" size="small" onClick={updateAssets}>
					{t('app_new_version_btn_refresh')}
				</Button>
			</>
		);
	};

	useEffect(() => {
		if (isUpdateAvailable) {
			showSuccess(t('app_new_version_available'), {
				persist: true,
				action: serviceWorkerRefreshAction()
			});
		}
	});

	return null;
};

export default SetupUpdater;
