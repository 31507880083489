export default (theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarSpacer: theme.mixins.toolbar,
	menuButton: {
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginRight: theme.spacing(2)
		}
	},
	drawerPaper: {
		width: 240
	},
	menu: {
		marginLeft: 'auto'
	},
	title: {
		flexGrow: 1,
		fontFamily: 'Nanum Pen Script',
		fontSize: '1.5rem',
		[theme.breakpoints.up('sm')]: {
			fontSize: '2rem'
		},
		'@media (max-width:380px)': {
			color: 'transparent'
		}
	},
	sidebar: {
		display: 'flex',
		flexShrink: 0,
		height: '100%',
		boxSizing: 'border-box',
		flexDirection: 'column'
	},
	sidebarTopList: {
		flexGrow: 1
	},
	buttonRoot: {
		color: 'white'
	},
	notificationItem: {
		alignItems: 'flex-start'
	},
	notificationItemIcon: {
		minWidth: theme.spacing(4)
	},
	notificationItemText: {
		whiteSpace: 'normal'
	}
});
