import React, { useState } from 'react';
import { useAuth, useUser, useDatabase } from 'reactfire';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import UserPicture from '../UserPicture';
import * as PATHS from 'constants/paths';
import { PRESENCE_COLLECTION } from 'constants/firebasePaths';
import styles from './NavigationBar.styles';

const useStyles = makeStyles(styles);

// Fix für: Logout > Login >> Missing or invalid permission
// https://github.com/FirebaseExtended/reactfire/discussions/228
const clearFirestoreCache = () => {
	const map = globalThis._reactFirePreloadedObservables;
	Array.from(map.keys()).forEach((key) => key.includes('firestore') && map.delete(key));
};

const AccountMenu = () => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const auth = useAuth();
	const database = useDatabase();
	const { ServerValue } = useDatabase;
	const { data: user } = useUser();
	const { t } = useTranslation();
	const menuId = 'main-account-menu';

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => setAnchorEl(null);

	const logout = async () => {
		closeMenu();
		// redirect to '/login' will occur if on a route where auth is required
		const userRef = database.ref(`${PRESENCE_COLLECTION}/${user.uid}`);
		await userRef.child('online').set(false);
		await userRef.child('lastSeen').set(ServerValue.TIMESTAMP);
		return auth.signOut().then(() => clearFirestoreCache());
	};

	if (!user) {
		return null;
	}

	return (
		<>
			<IconButton
				aria-owns={anchorEl ? menuId : null}
				aria-controls={anchorEl ? menuId : null}
				aria-haspopup="true"
				onClick={handleMenu}
				classes={{ root: classes.buttonRoot }}>
				<UserPicture
					url={user.photoURL}
					name={user.displayName}
					scale={0.8}
					isOnline={navigator.onLine}
					context="toolbar"
				/>
			</IconButton>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={Boolean(anchorEl)}
				onClose={closeMenu}>
				<MenuItem component={Link} to={PATHS.ACCOUNT_PATH} onClick={closeMenu}>
					{t('btn_account')}
				</MenuItem>
				<MenuItem component={Link} to={PATHS.SETTINGS_PATH} onClick={closeMenu}>
					{t('btn_settings')}
				</MenuItem>
				<MenuItem onClick={logout}>{t('btn_logout')}</MenuItem>
			</Menu>
		</>
	);
};

export default AccountMenu;
