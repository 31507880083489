import React, { useState } from 'react';
import { AuthCheck } from 'reactfire';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDarkMode } from 'modules/theme';
import { useMessaging } from 'modules/messaging';
import styles from './NavigationBar.styles';
import AccountMenu from './AccountMenu';
// import LanguageMenu from './LanguageMenu';
import NotificationMenu from './NotificationMenu';
import NavigationItem from './NavigationItem';

import { AppBar, Divider, Drawer, IconButton, List, Toolbar, Tooltip, Typography } from '@material-ui/core';

import {
	Brightness4 as DarkThemeIcon,
	BrightnessHigh as LightThemeIcon,
	HomeRounded as HomeIcon,
	InfoRounded as InfoIcon,
	MenuRounded as MenuIcon,
	MoodRounded as MoodIcon,
	SupervisedUserCircle as SupervisorAccountIcon
} from '@material-ui/icons';

import * as PATHS from 'constants/paths';

const useStyles = makeStyles(styles);

const NavigationBar = () => {
	const classes = useStyles();
	const { toggleDarkMode, isDarkMode } = useDarkMode();
	const messaging = useMessaging();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const onOpen = () => setOpen(true);
	const onClose = (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

		setOpen(false);
	};

	return (
		<>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="Menü öffnen"
						onClick={onOpen}
						className={classes.menuButton}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" noWrap className={classes.title}>
						Schwasenphrein
					</Typography>
					<Tooltip title="Toggle light/dark theme">
						<IconButton color="inherit" onClick={toggleDarkMode} className={classes.themeModeButton}>
							{isDarkMode ? <LightThemeIcon /> : <DarkThemeIcon />}
						</IconButton>
					</Tooltip>
					{/* <LanguageMenu /> */}
					<AuthCheck fallback={null}>
						{messaging.isSupported && messaging.isPermissionGranted ? <NotificationMenu /> : null}
						<AccountMenu />
					</AuthCheck>
				</Toolbar>
			</AppBar>
			<Drawer anchor="left" open={open} classes={{ paper: classes.drawerPaper }} onClose={onClose}>
				<div className={classes.sidebar} tabIndex={0} role="presentation" onClick={onClose} onKeyDown={onClose}>
					<List component="nav" className={classes.sidebarTopList}>
						<NavigationItem to={PATHS.LANDING_PATH} primary={t('menu_start')} icon={<HomeIcon />} />
						<AuthCheck fallback={null}>
							<NavigationItem to={PATHS.POSTS_PATH} primary={t('menu_posts')} icon={<MoodIcon />} />
							<NavigationItem to={PATHS.ABOUT_PATH} primary={t('menu_about')} icon={<InfoIcon />} />
						</AuthCheck>
						<AuthCheck fallback={null} requiredClaims={{ admin: true }}>
							<Divider />
							<NavigationItem
								to={PATHS.ADMIN_PATH}
								primary={t('menu_admin')}
								icon={<SupervisorAccountIcon />}
							/>
						</AuthCheck>
					</List>
					<List dense>
						<NavigationItem to={PATHS.IMPRINT_PATH} primary={t('menu_imprint')} />
						<NavigationItem to={PATHS.PRIVACY_POLICY_PATH} primary={t('menu_privacy_policy')} />
					</List>
				</div>
			</Drawer>
		</>
	);
};

export default NavigationBar;
