export default (theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	main: {
		...theme.flexRowCenter,
		padding: theme.spacing(3, 1)
	},
	content: {
		justifyContent: 'space-around',
		flexBasis: '100%',
		[theme.breakpoints.up('sm')]: {
			flexBasis: '80%'
		},
		[theme.breakpoints.up('lg')]: {
			flexBasis: '60%'
		}
	}
});
