import React from 'react';
import PropTypes from 'prop-types';

const Pig = ({ width, height }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 80 ">
			<path
				d="M 49.55 27.939 C 49.65 28.539 50.05 29.039 50.65 29.139 C 59.35 31.239 68.85 27.539 73.55 19.539 C 75.95 15.439 76.75 10.839 76.15 6.539 C 76.05 5.939 75.65 5.439 75.05 5.339 C 66.25 3.239 56.85 6.939 52.15 14.939 C 49.75 19.039 48.95 23.639 49.55 27.939 Z"
				fill="#f79ec2"
			/>
			<path
				d="M 46.75 32.739 C 46.85 33.339 47.25 33.839 47.85 33.939 C 56.55 36.039 66.05 32.339 70.75 24.339 C 73.15 20.239 73.95 15.639 73.35 11.339 C 73.25 10.739 72.85 10.239 72.25 10.139 C 63.55 8.039 54.05 11.739 49.35 19.739 C 46.95 23.839 46.15 28.439 46.75 32.739 Z"
				fill="#ffffff"
			/>
			<path
				d="M 30.45 27.939 C 30.35 28.539 29.95 29.039 29.35 29.139 C 20.65 31.239 11.15 27.539 6.45 19.539 C 4.05 15.439 3.25 10.839 3.85 6.539 C 3.95 5.939 4.35 5.439 4.95 5.339 C 13.65 3.239 23.15 6.939 27.85 14.939 C 30.25 19.039 31.05 23.639 30.45 27.939 Z"
				fill="#f79ec2"
			/>
			<path
				d="M 34.15 34.039 C 34.05 34.639 33.65 35.139 33.05 35.239 C 24.35 37.339 14.85 33.639 10.15 25.639 C 7.75 21.539 6.95 16.939 7.55 12.639 C 7.65 12.039 8.05 11.539 8.65 11.439 C 17.35 9.339 26.85 13.039 31.55 21.039 C 33.95 25.139 34.75 29.739 34.15 34.039 Z"
				fill="#ffffff"
			/>
			<path
				d="M 8.2 49.739 C 9.744 56.505 13.397 62.609 18.629 67.169 C 23.861 71.728 30.407 74.512 37.321 75.116 C 44.234 75.721 51.165 74.116 57.109 70.534 C 63.053 66.952 67.709 61.574 70.405 55.179 C 73.1 48.784 73.697 41.695 72.11 34.939 C 70.566 28.173 66.913 22.069 61.681 17.509 C 56.449 12.95 49.903 10.166 42.989 9.562 C 36.076 8.957 29.145 10.562 23.201 14.144 C 17.257 17.726 12.601 23.104 9.905 29.499 C 7.21 35.894 6.613 42.983 8.2 49.739 Z"
				fill="#ffb5d2"
			/>
			<path
				d="M 43.25 72.039 C 52.85 72.039 60.65 66.639 60.65 60.039 C 60.65 53.439 52.85 48.039 43.25 48.039 C 33.65 48.039 25.85 53.439 25.85 60.039 C 25.85 66.639 33.65 72.039 43.25 72.039 Z"
				fill="#de719c"
			/>
			<path
				d="M 40.95 68.239 C 50.55 68.239 58.35 62.839 58.35 56.239 C 58.35 49.639 50.55 44.239 40.95 44.239 C 31.35 44.239 23.55 49.639 23.55 56.239 C 23.55 62.839 31.35 68.239 40.95 68.239 Z"
				fill="#ffb5d2"
			/>
			<path
				d="M 40.15 65.739 C 49.75 65.739 57.55 60.339 57.55 53.739 C 57.55 47.139 49.75 41.739 40.15 41.739 C 30.55 41.739 22.75 47.139 22.75 53.739 C 22.75 60.339 30.55 65.739 40.15 65.739 Z"
				fill="#f79ec2"
			/>
			<path
				d="M 31.75 57.339 C 33.75 57.339 35.35 55.739 35.35 53.739 C 35.35 51.739 33.75 50.139 31.75 50.139 C 29.75 50.139 28.15 51.739 28.15 53.739 C 28.15 55.739 29.85 57.339 31.75 57.339 Z M 48.55 57.339 C 50.55 57.339 52.15 55.739 52.15 53.739 C 52.15 51.739 50.55 50.139 48.55 50.139 C 46.55 50.139 44.95 51.739 44.95 53.739 C 44.95 55.739 46.55 57.339 48.55 57.339 Z"
				fill="#de719c"
			/>
			<path
				d="M 49.35 38.339 C 52.25 38.339 54.65 36.039 54.65 33.139 C 54.65 30.239 52.25 27.939 49.35 27.939 C 46.45 27.939 44.05 30.239 44.05 33.139 C 44.05 36.039 46.45 38.339 49.35 38.339 Z"
				fill="#f9f9f9"
			/>
			<path
				d="M 49.35 35.839 C 50.85 35.839 52.15 34.639 52.15 33.139 C 52.15 31.639 50.95 30.439 49.35 30.439 C 47.85 30.439 46.55 31.639 46.55 33.139 C 46.55 34.639 47.85 35.839 49.35 35.839 Z"
				fill="#334160"
			/>
			<path
				d="M 30.65 41.339 C 35.25 41.339 39.05 37.639 39.05 33.039 C 39.05 28.439 35.25 24.739 30.65 24.739 C 26.05 24.739 22.25 28.439 22.25 33.039 C 22.25 37.639 26.05 41.339 30.65 41.339 Z"
				fill="#f9f9f9"
			/>
			<path
				d="M 30.65 38.339 C 33.55 38.339 35.95 36.039 35.95 33.139 C 35.95 30.239 33.55 27.939 30.65 27.939 C 27.75 27.939 25.35 30.239 25.35 33.139 C 25.35 36.039 27.75 38.339 30.65 38.339 Z"
				fill="#334160"
			/>
		</svg>
	);
};

Pig.propTypes = {
	width: PropTypes.any,
	height: PropTypes.any
};

export default Pig;
