import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { AppBar, Card, CardContent, CardHeader, Toolbar, Typography } from '@material-ui/core';
import { SentimentDissatisfied as SadIcon } from '@material-ui/icons';

import styles from './ErrorFallback.styles';

const useStyles = makeStyles(styles);

const ErrorFallback = ({ error, resetErrorBoundary }) => {
	const classes = useStyles();

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" color="inherit" noWrap className={classes.title}>
						Schwasenphrein
					</Typography>
				</Toolbar>
			</AppBar>
			<main className={classes.main}>
				<div className={classes.content}>
					<Card>
						<CardHeader
							className={classes.cardHeader}
							title={
								<Typography gutterBottom variant="h5">
									Ooops!
								</Typography>
							}
							avatar={<SadIcon />}
							disableTypography
						/>
						<CardContent className={classes.cardContent}>
							<Typography className={classes.postText}>Fehlermeldung:</Typography>
							<pre>{error.message}</pre>
							<Typography className={classes.postText}>Stacktrace:</Typography>
							<pre>{error.stack}</pre>
						</CardContent>
					</Card>
				</div>
			</main>
		</>
	);
};

ErrorFallback.propTypes = {
	error: PropTypes.object,
	resetErrorBoundary: PropTypes.func
};

export default ErrorFallback;
