import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'reactfire';
import { makeStyles } from '@material-ui/core/styles';
import { useNotifications } from 'modules/notification';
import { useTranslation } from 'react-i18next';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	TextField
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { PASSWORD_FORGET_PATH } from 'constants/paths';
import styles from './SignIn.styles';

const useStyles = makeStyles(styles);

const SignIn = () => {
	const classes = useStyles();
	const auth = useAuth();
	const { showError, showWarning } = useNotifications();
	const { t } = useTranslation();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [open, setOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const onShow = () => setOpen(true);
	const onCancel = () => setOpen(false);
	const onChangeEmail = (event) => setEmail(event.target.value);
	const onChangePassword = (event) => setPassword(event.target.value);

	const onShowPasswordClick = () => setShowPassword(!showPassword);
	const onPasswordMouseDown = (event) => event.preventDefault();
	const onPasswordKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onLogin();
		}
	};

	const onOk = (event) => {
		event.preventDefault();
		onLogin();
	};

	const onLogin = async () => {
		if (!navigator.onLine) {
			showWarning(t('msg_not_online'));
			return;
		}

		try {
			await auth.signInWithEmailAndPassword(email.trim(), password);
			// State-Änderungen nach component unload, da der Login direkt die Seite wechselt
			// setOpen(false);
			// setPassword('');
			// setEmail('');
		} catch (error) {
			console.error(error);
			if (error.code && error.code === 'auth/user-not-found') {
				showError(t('sign_in_msg_no_user_found'), { autoHideDuration: 5000 });
			} else if (error.code && error.code === 'auth/wrong-password') {
				showError(t('sign_in_msg_wrong_password'));
			} else if (error.code && error.code === 'auth/invalid-email') {
				showError(t('sign_in_msg_invalid_email_format'));
			} else {
				showError(t('sign_in_msg_login_not_successful'));
			}
		}
	};

	return (
		<>
			<Button variant="contained" color="secondary" onClick={onShow}>
				{t('sign_in_btn_login')}
			</Button>
			<Dialog open={open} onClose={onCancel} maxWidth="md" aria-labelledby="signIn-dialog-title">
				<DialogTitle id="signIn-dialog-title">{t('sign_in_title')}</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item xs={12} className={classes.containerEmail}>
							<TextField
								required
								fullWidth
								id="input-email"
								label={t('sign_in_label_mail')}
								autoComplete="email"
								value={email}
								vartiant="outlined"
								onChange={onChangeEmail}
							/>
						</Grid>
						<Grid item xs={12} className={classes.containerPassword}>
							<FormControl fullWidth required>
								<InputLabel htmlFor="input-password">{t('sign_in_label_password')}</InputLabel>
								<Input
									id="input-password"
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={onChangePassword}
									onKeyPress={onPasswordKeyPress}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={onShowPasswordClick}
												onMouseDown={onPasswordMouseDown}>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className={classes.dialogActionsRoot} disableSpacing>
					<Button component={Link} to={PASSWORD_FORGET_PATH} color="secondary">
						{t('sign_in_btn_password_lost')}
					</Button>
					<Button onClick={onCancel} color="primary" className={classes.buttonCancel}>
						{t('btn_cancel')}
					</Button>
					<Button onClick={onOk} color="primary" disabled={password === '' || email === ''}>
						{t('sign_in_btn_login')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SignIn;
