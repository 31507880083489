export default (theme) => ({
	main: {
		...theme.flexRowCenter,
		padding: theme.spacing(3, 1),
		paddingTop: theme.spacing(8)
	},
	containerPig: {
		marginBottom: theme.spacing(4)
	},
	root: {
		...theme.flexColumnCenter,
		paddingTop: theme.spacing(2)
	},
	section: {
		...theme.flexColumnCenter,
		padding: theme.spacing(2),
		textAlign: 'center'
	}
});
