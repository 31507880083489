export const LIST_PATH = '/projects';
export const ACCOUNT_PATH = '/account';
export const LOGIN_PATH = '/login';
export const SIGNUP_PATH = '/signup';
export const LANDING_PATH = '/';
export const SINGLE_POST_PATH = '/post';
export const POSTS_PATH = '/posts';
export const ADMIN_PATH = '/admin';
export const ABOUT_PATH = '/info';
export const IMPRINT_PATH = '/impressum';
export const PRIVACY_POLICY_PATH = '/datenschutz';
export const SETTINGS_PATH = '/settings';
export const PASSWORD_FORGET_PATH = '/passwort-vergessen';
